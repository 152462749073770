
export function helper(text) {

    let split = text.split("ORDER ID");

    split.shift();

    const extracted = split.map((item, index) => {

        item = item.split("\n");

        console.log(item);

        let name = [...item].filter(Boolean);
        name.splice(-2, 2);

        let phone = [...name].filter(Boolean);
        phone.splice(-1, 1);

        let fullAddress = (item[14] + " " + item[15]).split(",").map(e => e.trim()).filter(Boolean);

        if (item.length === 20) fullAddress = (item[14]).split(",").map(e => e.trim()).filter(Boolean);
        else if (item.length === 23) fullAddress = (item[14] + " " + item[15] + " " + item[16]).split(",").map(e => e.trim()).filter(Boolean);
        else if (item.length === 24) fullAddress = (item[14] + " " + item[15] + " " + item[16] + " " + item[17]).split(",").map(e => e.trim()).filter(Boolean);


        let province = fullAddress[fullAddress.length - 1];
        fullAddress.pop();

        const district = fullAddress[fullAddress.length - 1];
        fullAddress.pop();

        const address = fullAddress.join(", ");

        const zip = [...item].filter(Boolean);

        const data = {
            WayBill: '',
            Package_Number: '',
            Recipient_Name: name[name.length - 1],
            Recipient_Phone: phone[phone.length - 1],
            Recipient_Province: province,
            Recipient_District: district,
            Recipient_Address_details: address,
            Item_Type: '',
            Weight_Kg: '',
            Recipient_Post_Code: item[13],
            Recipient_Email: '',
            COD_Amount: '',
            Product_Name: '',
            Insured_Amount: '',
            Lenght_CM: '',
            Width_CM: '',
            Height_CM: '',
            Remark: item[6],
        }

        return data;

    });

    return extracted;
}
