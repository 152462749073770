import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { helper } from './helper';
import exportToExcel from './exportToExcel';
const pdfjsLib = window.pdfjsLib;
pdfjsLib.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.js`;
function App() {
    const [items, setItems] = useState([]);

    console.log(items);

    const readPdf = (file) => {

        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = (e) => {
                const bufferData = e.target.result;
                resolve(bufferData);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((dataBuffer) => {
            var loadingTask = pdfjsLib.getDocument(dataBuffer);
            loadingTask.promise
                .then((pdf) => {
                    let pdfDocument = pdf;
                    var pagesPromises = [];
                    for (let i = 0; i < pdf.numPages; i++) {
                        ((pageNumber) => {
                            pagesPromises.push(getPageText(pageNumber, pdfDocument));
                        })(i + 1);
                    }
                    Promise.all(pagesPromises).then((pagesText) => {
                        let result = helper(pagesText.join("\n"));
                        setItems(items => items.concat(result));

                    });
                })
                .catch((error) => {
                    console.error("Error: " + error);
                });
        });
    }
    /**
    * @param {Integer} pageNum Specifies the number of the page
    * @param {PDFDocument} PDFDocumentInstance The PDF document obtained
    **/
    function getPageText(pageNum, PDFDocumentInstance) {
        return new Promise((resolve, reject) => {
            PDFDocumentInstance.getPage(pageNum).then((pdfPage) => {
                pdfPage.getTextContent().then((textContent) => {
                    let lastY, text = '';
                    for (let item of textContent.items) {
                        if (lastY === item.transform[5] || !lastY) {
                            text += item.str;
                        }
                        else {
                            text += '\n' + item.str;
                        }
                        lastY = item.transform[5];
                    }

                    resolve(text);

                });
            });
        });
    };
    return (
        <div className="container">
            <div className="upload">
                <div className="alert alert-info">
                    <strong>Upload .pdf files here</strong>
                </div>
                <input
                    type="file"
                    accept="application/pdf"
                    className="form-control file"
                    onClick={(e) => {
                        e.target.value = null;
                    }}
                    onChange={(e) => {
                        const file = e.target.files;
                        for (let i = 0; i < file.length; ++i) {
                            readPdf(file[i]);
                        }
                    }}
                />
            </div>
            <div className="upload">
                <button className="btn btn-success" onClick={() => exportToExcel(items)}>
                    Download Batch Import 🙂
                </button>

            </div>
            <div className="upload">

                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Province</th>
                            <th scope="col">District</th>
                            <th scope="col">Address</th>
                            <th scope="col">Post Code</th>
                            <th scope="col">Remark</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((d, index) => (
                            <tr key={index}>
                                <td>{d.Recipient_Name}</td>
                                <td>{d.Recipient_Phone}</td>
                                <td>{d.Recipient_Province}</td>
                                <td>{d.Recipient_District}</td>
                                <td>{d.Recipient_Address_details}</td>
                                <td>{d.Recipient_Post_Code}</td>
                                <td>{d.Remark}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
        </div>
    );
}

export default App;
