const download = (url, name) => {
    let a = document.createElement('a')
    a.href = url
    a.download = name
    a.click()

    window.URL.revokeObjectURL(url)
}


function s2ab(s) {
    const buf = new ArrayBuffer(s.length)

    const view = new Uint8Array(buf)

    for (let i = 0; i !== s.length; ++i)
        view[i] = s.charCodeAt(i) & 0xFF

    return buf
}

const exportToExcel = data => {
    import('xlsx').then(XLSX => {
        const ws = XLSX.utils.json_to_sheet(data);

        ws.A1.v = "WayBill";
        ws.B1.v = "Package Number";
        ws.C1.v = "Recipient's Name";
        ws.D1.v = "Recipient's Phone";
        ws.E1.v = "Recipient's Province";
        ws.F1.v = "Recipient's District";
        ws.G1.v = "Recipient's Address Details";
        ws.H1.v = "Item Type";
        ws.I1.v = "Weight (Kg)";
        ws.J1.v = "Recipient's Post Code";
        ws.K1.v = "Recipient's Email";
        ws.L1.v = "COD Amount";
        ws.M1.v = "Product Name";
        ws.N1.v = "Insured Amount";
        ws.O1.v = "Lenght (cm)";
        ws.P1.v = "Width (cm)";
        ws.Q1.v = "Height (cm)";
        ws.R1.v = "Remark";
        // var wsrows = [
        //     { hpx: 25 },
        // ];
        // ws['!rows'] = wsrows;
        // let wscols = [
        //     { wch: 20 },
        //     { wch: 20 },
        //     { wch: 20 },
        //     { wch: 20 },
        //     { wch: 20 },
        //     { wch: 20 },
        //     { wch: 35 },
        //     { wch: 20 },
        //     { wch: 20 },
        //     { wch: 20 },
        //     { wch: 20 },
        //     { wch: 20 },
        //     { wch: 20 },
        //     { wch: 20 },
        //     { wch: 20 },
        //     { wch: 20 },
        //     { wch: 20 },
        //     { wch: 20 },
        // ];
        // ws['!cols'] = wscols;

        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "contacts");

        const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' })


        let url = window.URL.createObjectURL(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }))

        download(url, 'export.xlsx')
    })
}

export default exportToExcel;